<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <v-row>
      <v-col>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          color="primary"
        />
      </v-col>
    </v-row>
    <sequential-entrance from-left>
      <v-row class="hidden-md-and-down">
        <v-col
          v-for="(button, index) in menuforstatus"
          :key="index"
          class="justify-space-around"
        >
          <base-material-card
            v-if="!loading"
            :color="button.color"
            :icon="button.icon"
            inline
            link
            :to="button.nav"
          >
            <template v-slot:after-heading>
              <div class="text-h4 font-weight-light">
                {{ button.text }}
              </div>
            </template>
          </base-material-card>
        </v-col>
      </v-row>
    </sequential-entrance>
    <sequential-entrance from-top>
      <v-row>
        <v-col
          sm="12"
          md="12"
          lg="12"
        >
          <base-material-card
            color="black"
            icon="mdi-incognito"
            inline
          >
            <template v-slot:after-heading>
              <div class="text-h3 font-weight-light">
                Applicant Spy
              </div>
            </template>
            <v-dialog
              v-model="dialog"
              max-width="800px"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">Applicant Details</span>
                </v-card-title>

                <v-card-text>
                  Applicant: {{ editedItem.Name }}
                </v-card-text>
                <v-card-text v-if="editedItem.detail">
                  <v-row>
                    <v-col cols="6">
                      Class: {{ editedItem.detail.summary.propclass }} <br><br>
                      Type: {{ editedItem.detail.summary.proptype }} <br><br>
                      Year Built: {{ editedItem.detail.summary.yearbuilt }} <br><br>

                      Parking: {{ editedItem.detail.building.parking.prkgSpaces }} Spaces<br><br>
                      Total Rooms {{ editedItem.detail.building.rooms.roomsTotal }} <br><br>
                      Bath Rooms {{ editedItem.detail.building.rooms.bathstotal }} <br><br>
                      Bed Rooms {{ editedItem.detail.building.rooms.beds }} <br><br>

                      Lot Size (acres) {{ editedItem.detail.lot.lotsize1 }} <br><br>
                      Pool {{ editedItem.detail.lot.poolind || 'NA' }} <br><br>
                    </v-col>
                    <v-col cols="6">
                      <v-card-text v-if="editedItem.valuation">
                        Tax Assessment: {{ editedItem.valuation.assessment.assessed.assdttlvalue }} <br><br>
                        Market Value: {{ editedItem.valuation.assessment.market.mktttlvalue }} <br><br>
                        Yearly Property Tax: {{ editedItem.valuation.assessment.tax.taxamt }}<br>
                        Property Tax Year: {{ editedItem.valuation.assessment.tax.taxyear }}
                      </v-card-text>
                      <v-card-text v-if="editedItem.detail">
                        Recommend: Pet (lot size) , Auto (multiple parking spaces), Travel (Retired)
                      </v-card-text>
                      <v-card-text v-if="!editedItem.detail">
                        No Property Detail
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text v-if="editedItem.history">
                  <v-row>
                    <v-col cols="6">
                      Sale History: <br>
                      Amount: {{ editedItem.history.amount.saleamt }} <br><br>
                      Date: {{ editedItem.history.amount.salerecdate }} <br><br>
                      Type: {{ editedItem.history.amount.saletranstype }} <br><br>
                    </v-col>
                    <v-col cols="6">
                      <v-card-text v-if="editedItem.history">
                        Recommend: Update Valuation
                      </v-card-text>
                      <v-card-text v-if="!editedItem.history">
                        No Property history
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-data-table
              :headers="headers"
              :items="getpartners"
              :search="search"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-toolbar-title>Applicants</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  />
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  />
                  <v-spacer />
                </v-toolbar>
              </template>
              <template v-slot:[`item.details`]="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-account-details
                </v-icon>
              </template>
              <template v-slot:[`item.campaigns`]="{ item }">
                <v-btn
                  outlined
                  color="indigo"
                  class="mr-2"
                  :to="'/partnercampaigns/' + item.key"
                >
                  <v-icon>mdi-handshake</v-icon>Campaigns
                </v-btn>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  class="mr-2"
                  color="purple"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                >
                  Reset
                </v-btn>
              </template>
            </v-data-table>
          </base-material-card>
        </v-col>
      </v-row>
    </sequential-entrance>
  </v-container>
</template>

<script>
  import moment from 'moment'

  export default {

    data: () => ({
      dialog: false,
      dialogDelete: false,
      search: '',
      series: [{
        name: 'Website Blog',
        type: 'column',
        data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
      }, {
        name: 'Social Media',
        type: 'line',
        data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
      }],
      headers: [
        { text: 'Name', value: 'Name', align: 'left', sortable: false },
        { text: 'Email', value: 'Email', align: 'left', sortable: false },
        { text: 'Industry', value: 'Industry', align: 'left', sortable: false },
        { text: 'Address', value: 'Address', align: 'center', sortable: false },
        { text: 'City', value: 'City', align: 'right', sortable: false },
        { text: 'State', value: 'State', align: 'right', sortable: false },
        { text: 'Zipcode', value: 'Zipcode', align: 'right', sortable: false },
        { text: 'Premium', value: 'AnnualPremium', align: 'right', sortable: false },
        { text: 'Details', value: 'details', align: 'right', sortable: false },

      ],
      valid: false,
      applicants: [],
      partnerstatus: ['NEW', 'APPROVED', 'DECLINED', 'UNQUALIFIED'],
      editedIndex: -1,
      max: 50,
      allowSpaces: false,
      unique: true,
      filterchars: true,
      onlyspace: false,
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [0, 4]
        },
        title: {
          text: 'Traffic Sources'
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
        labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
        xaxis: {
          type: 'datetime'
        },
        yaxis: [{
          title: {
            text: 'Website Blog',
          },

        }, {
          opposite: true,
          title: {
            text: 'Social Media'
          }
        }]
      },
      editedItem: {
        Name: '',
        Email: '',
        Industry: '',
        Address: '',
        State: '',
        City: '',
        industries: '',
        partnerurl: '',
        key: '',
      },
      defaultItem: {
        name: '',
        email: '',
        message: '',
        phone: '',
        company: '',
        companywebsite: '',
        industries: '',
        partnerurl: '',
        key: '',
      },
    }),
    computed: {
      menuforstatus () {
        return [
          {
            text: 'Sign Ups by Partner',
            value: 'deposits',
            color: 'green',
            icon: 'mdi-account-cash-outline',
            nav: '/ReportsPartnersAccounts'
          },
          {
            text: 'Sales by Partner',
            value: 'cit',
            color: 'purple',
            icon: 'mdi-transfer-right',
            nav: '/ReportPartnersSales'
          },
          {
            value: 'cashin',
            text: 'Traffic By Partner',
            color: 'orange',
            icon: 'mdi-calendar-multiple',
            nav: '/ReportPartnersTraffic'
          },
          {
            value: 'monthly',
            text: 'Monthly Volume',
            color: 'red',
            icon: 'mdi-chart-areaspline',
            nav: '/ReportPartnersVolume'
          }
        ]
      },
      validForm () {
        let validform = false
        if (this.email && this.phone && this.company && this.companywebsite && this.industries) {
          validform = true
        }
        return validform
      },
      loading () {
        // return true
        return this.$store.getters.getloading
      },
      getpartners () {
        this.applicants = []
        console.log(this.$store.getters.getDataSpyApplicants)
        if (this.$store.getters.getDataSpyApplicants) {
          this.applicants = this.$store.getters.getDataSpyApplicants
          this.applicants.forEach(entry => {
            Object.assign(entry, { detail: null })
            Object.assign(entry, { history: null })

            if (entry.propertydetail) {
              if (entry.propertydetail.property) {
                entry.detail = entry.propertydetail.property[0]
              }
            }
            if (entry.saleshistory) {
              if (entry.saleshistory.property) {
                entry.history = entry.saleshistory.property[0].salehistory[0]
              }
            }
          })
        }
        console.log('our applicants ', this.applicants)
        return this.applicants
      }
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created () {
      this.$store.dispatch('loaddataspy')
    },
    methods: {
      gettopurl (nav) {
        let url = nav
        return url
      },
      initialize () {
        this.partners = this.$store.getters.getLoadedBlanketPartners
      },
      editItem (item) {
        this.editedIndex = this.applicants.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.applicants.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.applicants.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

    },
  }
</script>

<style lang="scss" scoped>
.policy-numbers {
  font-size: 36px;
}
</style>
